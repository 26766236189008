@import "./mixin.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

body{
    background-color: $beige;
}

.contain-wrapper {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .contain{
    max-width: 2000px;
    width: 100%;
  }

  h1{
    font-family: 'Futura';
    font-weight: normal;
  }
  h2{
    font-family: 'Verona';
    font-weight: normal;
  }

  p{
    font-size: 'Verona';
    font-weight: normal;
  }

  .siren{
    color: $beige;
    text-decoration: none;
    display: flex;
    justify-content: center;
    padding-bottom: 0.5em;
    font-size: 14px;
    &:hover{
      text-decoration: underline;
    }
  }