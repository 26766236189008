@import "../../scss/mixin.scss";

.footer-wrapper {
    background-color: $green;
    width: 100%;
    display: flex;
    flex-direction: column;
    @include mobile {
        height: auto;
        padding: 1.5rem;
    }
    .company-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
        @include mobile {
            flex-direction: column;
        }
    }
    .company {
        display: flex;
        width: 50%;
        height: 100%;
        justify-content: flex-start;
        flex-direction: column;
        padding: 0rem 3rem 3rem 3rem;
        @include mobile {
            flex-direction: column;
            width: 100%;
            padding: 0 1.5rem 1.5rem 1.5rem;
        }
    }
    .footer-logo {
        height: 50px;
        @include mobile {
            height: 100%;
        }
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid $beige;
        width: 100%;
        padding-bottom: 1em;
        @include mobile {
            align-items: flex-start;
            width: 100%;
            padding: 1.5rem 0;
        }

        img {
            height: 30px;
            @include mobile {
                height: 25px;
            }
        }
    }
    .agents {
        height: 50%;
        width: 100%;
        padding-top: 2.5rem;
        flex-wrap: wrap;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @include mobile {
            width: 100%;
            height: auto;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            padding-top: 1.5rem;
        }
        .agent {
            width: 50%;
            min-width: 250px;
            padding: 0.5rem 0;
            a {
                all: unset;
                cursor: pointer;
                :hover {
                    text-decoration: underline;
                }
            }
            p {
                color: $beige;
                font-family: "Futura";
                font-weight: normal;
                font-size: 16px;
                line-height: 26px;
            }
            @include mobile {
                width: 100%;
                padding: 1rem 0;
            }
        }
    }
    .contact {
        display: flex;
        width: 100%;
        padding: 3rem 3rem 6rem 3rem;
        h2 {
            font-size: 22px;
            color: $beige;
            width: 100%;
            @include mobile {
                font-size: 20px;
            }
        }
        @include mobile {
            padding: 1.5rem;
        }
    }
}
.company:nth-child(2) {
    img {
        height: 35px;
    }
}
.copyright {
    padding: 3rem;
    color: $beige;
    font-size: 12px;
    @include mobile {
        padding: 1.5rem;
    }
    p {
        font-family: "Futura";
        font-weight: normal;
        font-size: 12px;
    }
}

.green {
    background-color: $green;
}
