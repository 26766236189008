$beige : #d9d0be;
$green : #2c321d;
$orange : #f56333;
@mixin large-desktop {
    @media (min-width: 1500px) {
        @content;
    }
}


@mixin desktop {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin small-desktop {
    @media (min-width: 1024px) and (max-width: 1200px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: 1023px) {
        @content;
    }
}

@font-face {
    font-family: 'Futura';
    src: url('../../public/fonts/FuturaStd-Book.woff2') format('woff2'),
        url('../../public/fonts/FuturaStd-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Verona';
    src: url('../../public/fonts/VeronaSerial.woff2') format('woff2'),
        url('../../public/fonts/VeronaSerial.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Verona';
    src: url('../../public/fonts/VeronaSerial-Medium.woff2') format('woff2'),
        url('../../public/fonts/VeronaSerial-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
