@import "../../scss/global.scss";

.swiper {
    width: 100%;
    display: flex;
    justify-content: center;
    max-width: 100vw;
    .swiper-img-back {
        height: 90vh;
        background-size: cover;
        background-position: 50% 90%;
        background-repeat: no-repeat;
        width: 100%;
        @include mobile {
            height: 40vh;
        }
    }
    .swiper-pagination-bullet {
        opacity: 1;
        width: 16px;
        height: 16px;
        background: transparent;
        border: 2px solid $beige;
    }
    .swiper-pagination-bullet-active {
        background: $beige;
    }
    .swiper-button-prev {
        color: $orange;
        background-color: $beige;
        height: 66px;
        width: 66px;
        border-radius: 50%;
        @include mobile {
            display: none;
        }
    }
    .swiper-button-next {
        color: $orange;
        background-color: $beige;
        height: 66px;
        width: 66px;
        border-radius: 50%;
        @include mobile {
            display: none;
        }
    }
    .swiper-slide {
        position: relative;
        .swiper-title{
            padding: 0.5rem 1.5rem;
            position: absolute;
            
            bottom: 0;
            right: 0;
            p{
                color: $beige;
                font-family: 'Futura' ;
                text-align: right;
            }
            p:nth-child(2){
                font-size: 10px
            }
            @include mobile {
                font-size: 11px;
                padding: 0.5rem 0.5rem;
                top: 0;
                right: 0;
                height: fit-content;
                width: fit-content;                
            }
        }
    }
}
